import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: {
        Hakkımızda: "About us",
        Ürünlerimiz: "Products",
        Anasayfa: "Homepage",
        İletişim: "Contact us",
        "Sipariş kaydı": "Order Form",
        "1975’den Günümüze Alüminyum Ürünleri Tedarikçiniz":
          "Your Aluminium Product Supplier Since 1975",
        "Alüminyum Disk": "Aluminium Disc ",
        "Alüminyum Levha": "Aluminium Sheet",
        "Alüminyum Külçe": "Alüminyum Ingot",
        "1975 yılında Alsa Alüminyum adı altında imalata başlayıp günümüzde BTS Metal Sanayi olarak özellikle mutfak eşyaları başta olmak üzere elektrik, aydınlatma , havalandırma , aksesuar vb. üretim yapan firmalara Alüminyum Levha ve Disk üretmekteyiz.":
          "We started production under the name of Alsa Aluminum in 1975 and today as BTS Metal we are continuing production. We are supplying kitchenware, electricity, lighting, ventilation, accessories companies with aluminum discs and aluminum sheets.",
        "Çap Aralığı": "Diameter Range",
        "Kalınlık Aralığı": "Thickness Range",
        "Sipariş Kaydı Oluştur": "Create Order Form",
        "Sipariş kaydı oluştur": "Create order form",
        "Mininum Ölçüler": "Minimum Dimensions",
        "Maksimum Ölçüler": "Maximum Dimensions",
        "Alüminyum külçe üretimi hakkında detaylı bilgi almak için iletişime geçiniz.":
          "To get more info about aluminium ingots please contact us.",
        Adres: "Adress",
        Telefon: "Phone",
        Mail: "Mail",
        "Web Sitesi": "Web Site",
        "Whatsapp üzerinden hemen iletişime geçmek için tıklayın":
          "Click to get in contact via Whatsapp",
        "Sipariş kaydı oluşturun size en kısa sürede dönüş yapalım.":
          "Create an order for so we can contact you as soon as possible.",
        "Harita için tıklayın": "Harita için tıklayın-EN",
        "banner.png": "banner_en.png",
        "mobilbanner.png": "mobilbanner_en.png",
      },
    },
  },
  fallbackLng: "tr",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
