import { Container } from "./styled";
import { useTranslation } from "react-i18next";

function OrderForm() {
  const { i18n } = useTranslation();

  return (
    <Container>
      <iframe
        title="orderform"
        src={
          i18n.language === "tr"
            ? "https://docs.google.com/forms/d/e/1FAIpQLSfDrVGQoLjuI4wHSZDCVSVDr4uzPlCWsRbNYhGeXXTGjRnd1Q/viewform?embedded=true"
            : "https://docs.google.com/forms/d/e/1FAIpQLScqvgnuYRvT1JCpucNG2yRhGMes1UjNAKevejy1ytiL13KeMg/viewform?embedded=true"
        }
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </Container>
  );
}

export { OrderForm };
