import {
  Container,
  Logo,
  LogoContainer,
  Navigation,
  Link,
  Underline,
  Top,
  HamburgerMenu,
  MobileLinks,
  MobileLink,
  MenuIcon,
  ListItem,
  MenuContent,
  ButtonEN,
  ButtonTR,
} from "./styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

function Header() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [hamburgerMenuVisible, setHamburgerMenuVisible] = useState(false);
  const displayMenu = () => {
    setHamburgerMenuVisible(!hamburgerMenuVisible);
  };

  return (
    <Container>
      <Top>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
        <Navigation>
          <Link exact to="/" activeClassName={"isSelected"}>
            {t("Anasayfa")}
          </Link>
          <Link to="/hakkimizda" activeClassName={"isSelected"}>
            {t("Hakkımızda")}
          </Link>
          <Link to="/urunlerimiz" activeClassName={"isSelected"}>
            {t("Ürünlerimiz")}
          </Link>
          <Link to="/iletisim" activeClassName={"isSelected"}>
            {t("İletişim")}
          </Link>
        </Navigation>
        <HamburgerMenu>
          <MenuIcon onClick={displayMenu} />
          {hamburgerMenuVisible && (
            <MenuContent>
              <MobileLinks onClick={displayMenu}>
                <ListItem isSelected={pathname === "/"}>
                  <MobileLink exact to="/" activeClassName={"isSelected"}>
                    {t("Anasayfa")}
                  </MobileLink>
                </ListItem>
                <ListItem isSelected={pathname === "/hakkimizda"}>
                  <MobileLink to="/hakkimizda" activeClassName={"isSelected"}>
                    {t("Hakkımızda")}
                  </MobileLink>
                </ListItem>
                <ListItem isSelected={pathname === "/urunlerimiz"}>
                  <MobileLink to="/urunlerimiz" activeClassName={"isSelected"}>
                    {t("Ürünlerimiz")}
                  </MobileLink>
                </ListItem>
                <ListItem isSelected={pathname === "/iletisim"}>
                  <MobileLink to="/iletisim" activeClassName={"isSelected"}>
                    {t("İletişim")}
                  </MobileLink>
                </ListItem>
              </MobileLinks>
            </MenuContent>
          )}
        </HamburgerMenu>

        <ButtonEN
          isSelected={i18n.language === "en"}
          onClick={() => {
            i18n.changeLanguage("en");
          }}
        >
          EN
        </ButtonEN>
        <ButtonTR
          isSelected={i18n.language === "tr"}
          onClick={() => {
            i18n.changeLanguage("tr");
          }}
        >
          TR
        </ButtonTR>
      </Top>
      <Underline />
    </Container>
  );
}

export { Header };
