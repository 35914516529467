import {
  Container,
  Navigation,
  LogoContainer,
  Contact,
  Logo,
  Link,
  SocialMedia,
  Facebook,
  Instagram,
  Whatsapp,
  Information,
  PhoneIcon,
  Copyright,
  PhoneNumber,
} from "./styled";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <Container>
      <Information>
        <Contact>
          <PhoneIcon />
          <PhoneNumber>+90 288 263 40 82</PhoneNumber>
        </Contact>
        <LogoContainer>
          <Logo />
          <Copyright>BTS Metal © 2021</Copyright>
        </LogoContainer>
      </Information>

      <Navigation>
        <Link to="/">{t("Anasayfa")}</Link>
        <Link to="/hakkimizda">{t("Hakkımızda")}</Link>
        <Link to="/urunlerimiz">{t("Ürünlerimiz")}</Link>
        <Link to="/iletisim">{t("İletişim")}</Link>
        <Link to="/siparis-kaydi">{t("Sipariş kaydı")}</Link>
      </Navigation>
      <SocialMedia>
        <a href="https://www.facebook.com/btsmetalsanayi/">
          <Facebook />
        </a>
        <a href="https://www.instagram.com/btsmetal/">
          <Instagram />
        </a>

        <a
          href="https://api.whatsapp.com/send?phone=905323048558"
          target="_blank"
          rel="noreferrer"
        >
          <Whatsapp />
        </a>
      </SocialMedia>
    </Container>
  );
}

export { Footer };
