import {
  Container,
  Product,
  ProductDetail,
  ProductTitle,
  ProductTitleKulce,
  ProductContainer,
  Underline,
  Header,
  Title,
  ListItem,
  OrderForm,
  ProductImage,
  MaxOlculerIcon,
  MinOlculerIcon,
  KalinlikAraligiIcon,
  KalinlikAraligiLevhaIcon,
  CapAraligiIcon,
  Line,
  Top,
  Kulce,
} from "./styled";
import { useTranslation } from "react-i18next";

function Products() {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Title>{t("Ürünlerimiz")}</Title>
        <Underline />
      </Header>
      <ProductContainer>
        <Product>
          <ProductDetail>
            <Top>
              <ProductTitle>{t("Alüminyum Disk")}</ProductTitle>
              <ProductImage
                src="./images/aluminiumdisk.jpg"
                alt="Alüminyum Disk"
              />
            </Top>
            <ul>
              <ListItem>
                <CapAraligiIcon />
                <b>{t("Çap Aralığı")}:&nbsp;</b> 85mm - 1200mm
              </ListItem>
              <ListItem>
                <KalinlikAraligiIcon />
                <b>{t("Kalınlık Aralığı")}:&nbsp;</b> 0.60mm - 8.00mm
              </ListItem>
            </ul>
          </ProductDetail>
          <OrderForm
            to={{
              pathname: "/siparis-kaydi",
              state: { productType: "Alüminyum Disk" },
            }}
          >
            {t("Sipariş Kaydı Oluştur")}
          </OrderForm>
        </Product>
        <Product>
          <Line />

          <ProductDetail>
            <Top>
              <ProductTitle>{t("Alüminyum Levha")}</ProductTitle>
              <ProductImage
                src="./images/aluminiumlevha.jpg"
                alt="Alüminyum Levha"
              />
            </Top>

            <ul>
              <ListItem>
                <MinOlculerIcon />
                <b>{t("Mininum Ölçüler")}:&nbsp;</b> 100mm*100mm
              </ListItem>
              <ListItem>
                <MaxOlculerIcon />
                <b>{t("Maksimum Ölçüler")}:&nbsp;</b> 1000mm*900mm
              </ListItem>
              <ListItem>
                <KalinlikAraligiLevhaIcon />
                <b>{t("Kalınlık Aralığı")}:&nbsp;</b> 0.60mm - 3.00mm
              </ListItem>
            </ul>
          </ProductDetail>

          <OrderForm
            to={{
              pathname: "/siparis-kaydi",
              state: { productType: "Alüminyum Levha" },
            }}
          >
            {t("Sipariş Kaydı Oluştur")}
          </OrderForm>
        </Product>

        <Product>
          <Line />

          <ProductDetail>
            <Top>
              <ProductTitleKulce>{t("Alüminyum Külçe")}</ProductTitleKulce>
              <ProductImage
                src="./images/aluminiumkulce.jpg"
                alt="Alüminyum Külçe"
              />
            </Top>

            <ul>
              <Kulce>
                {t(
                  "Alüminyum külçe üretimi hakkında detaylı bilgi almak için iletişime geçiniz.",
                )}
              </Kulce>
            </ul>
          </ProductDetail>
          <OrderForm
            to={{
              pathname: "/siparis-kaydi",
              state: { productType: "Alüminyum Külçe" },
            }}
          >
            {t("Sipariş Kaydı Oluştur")}
          </OrderForm>
        </Product>
      </ProductContainer>
    </Container>
  );
}

export { Products };
